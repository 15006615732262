<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('countries.view')">
          <actions
            slot="actions"
            crud-links="countries"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list
            v-if="country.region"
            :condition="currentUser.can('Regions', 'view')"
            :title="$t('tables.headings.region')"
            :label="$t(country.region.name)"
            :to="{ name: 'regionsView', params: {id: country.region.id}}"
          />
          <text-list :title="$t('tables.headings.country')">
            {{ $t(country.name) }}
          </text-list>
          <text-list :title="$t('tables.headings.alphaCode')">
            {{ country.alpha_code }}
          </text-list>
          <text-list :title="$t('tables.headings.callingCode')">
            +{{ country.calling_code }}
          </text-list>
          <text-list
            v-if="country.manager"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.country_manager')"
            :label="country.manager.name"
            :to="{ name: 'usersView', params: {id: country.manager.id}}"
          />
          <text-list
            v-if="country.manager"
            :title="$t('tables.headings.country_manager_email')"
          >
            {{ country.manager.email }}
          </text-list>
          <text-list
            v-if="country.manager_integral"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.country_integral_manager')"
            :label="country.manager_integral.name"
            :to="{ name: 'usersView', params: {id: country.manager_integral.id}}"
          />
          <text-list
            v-if="country.manager_integral"
            :title="$t('tables.headings.country_integral_manager_email')"
          >
            {{ country.manager.email }}
          </text-list>
          <text-list
            v-show="country.updated_at"
            :title="$t('tables.headings.last_modified')"
          >
            {{ country.updated_at | date }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 sm8">
        <div class="flex xs12"
          v-if="currentUser.can('CountryManagers', 'index')"
        >
          <va-card :title="$t('countries.tabs.country_managers.title')">
            <change-managers
              :edit="false"
              :country="country"
              :loading="loading"
            />
          </va-card>
        </div>
        <div
          class="flex xs12"
          v-if="!!country.districts"
        >
          <va-card :title="$t('districts.index')">
            <local-table
              crud-links="districts"
              :editable="true"
              :top-options="[]"
              :action-options="['view', 'edit', 'delete']"
              :columns="districtsFields"
              :data="districts"
              :loading="loading"
              @view-item="$router.push({ name: 'districtsView', params: { id: $event.id }})"
              @edit-item="$router.push({ name: 'districtsEdit', params: { id: $event.id }})"
              @delete-item="tryDelete"
              @search-data="filterSearch"
              @download-item="prepareDownloadDistrict"
            />
          </va-card>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')
const ChangeManagers = () => import(/* webpackPrefetch: true */ './ChangeManagers')

export default {
  name: 'countries-view',
  components: {
    Actions,
    LocalTable,
    TextList,
    ChangeManagers,
  },
  data () {
    return {
      error: false,
      loading: false,
      country: {},
      districts: [],
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    districtsFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.district'),
          callback: this.translatedName,
        },
        {
          name: 'manager.name',
          title: this.$t('tables.headings.district_manager'),
        },
        {
          name: '__slot:actions',
          visible: this.districts.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    routeBuilder (id) {
      return `countries/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const countryId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(countryId))
      } catch (err) {
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.country = { ...u.data.data }
      if (this.country.districts) {
        this.districts = this.country.districts.slice(0)
      }
    },
    filterSearch (query) {
      if (query === '') {
        const countryDistricts = this.country.districts || []
        this.countries = countryDistricts.slice(0)
        return
      }
      this.districts = this.districts.filter(c => {
        let search = c.name.indexOf(query) > -1
        if (c.manager) {
          search = search || c.manager.name.indexOf(query) > -1
        }
        return search
      }).slice(0)
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        confirmButtonText: this.$t('layout.buttons.confirm'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          let response = false
          try {
            response = await this.$http.put('district/' + item.id, { country_id: null })
          } catch (error) {
            this.$swal.showValidationMessage(`Request failed: ${error}`)
          }

          return response.data
        },
      })

      if (result.value) {
        this.updateData()
      }
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.country.id) + '?export=' + format
      const downloadRoute = 'countries/download/'

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute)
    },
    async prepareDownloadDistrict (format) {
      const prepareRoute = 'districts/download?country=' + this.country.id
      const downloadRoute = 'districts/download/'

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            await this.$swal.queue([error])
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
